.app-mobile .nav {
  position: relative;
  z-index: 10;
  height: 66px;
  box-shadow: 2px 0px 10px rgb(0 0 0 / 20%);
  background-color: #fff;
}
.app-mobile .am-menu {
  position: absolute;
  width: 100%;
  /* box-shadow: 2px 0px 10px rgb(0 0 0 / 35%); */
  z-index: 10;
}
.app-mobile .nav .icon-wrap {
  padding: 10px;
}
.app-mobile .nav .menu-icon {
  width: 24px;
  height: 24px;
}
.app-mobile .nav .logo {
  max-height: 40px;
  width: auto;
  margin-left: 00;
}
.app-mobile .am-menu .am-list-item {
  padding-right: 15px;
}
.am-menu
  .am-flexbox
  .am-flexbox-item:last-child
  .am-list
  .am-list-item.am-sub-menu-item-selected {
  background-color: #efefef;
}
.app-mobile
  .am-menu
  .am-flexbox
  .am-flexbox-item:last-child
  .am-list
  .am-list-item.am-sub-menu-item-selected
  .am-list-line
  .am-list-content {
  color: #444;
}
.app-mobile
  .am-list
  .am-list-item.am-radio-item
  .am-list-line
  .am-list-extra
  .am-radio-inner {
  display: none;
}
/* .am-menu
  .am-flexbox
  .am-flexbox-item:last-child
  .am-list
  .am-list-item.am-sub-menu-item-selected
  .am-list-line
  .am-list-content {
  color: #333;
} */
html
  body
  .app-mobile.App
  .am-menu
  .am-flexbox
  .am-flexbox-item
  .am-list
  .am-list-item
  .am-list-line {
  /* padding: 15px 0 15px 0; */
  height: 50px;
  padding-left: 10px;
  border-bottom: 0.5px solid #d4d4d4;

  /* position: fixed;
  left: 100%;
  top: 66px;
  bottom: 0;
  background-color: #fff;
  overflow-y: auto;
  z-index: 120;
  padding: 30px 15px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: hidden; */
}
.app-mobile.App
  .am-menu
  .am-flexbox
  .am-flexbox-item
  .am-list
  .am-list-item
  .am-list-line
  .am-list-content {
  font-size: 14px;
}
.app-mobile .menu-mask {
  content: " ";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.15;
  z-index: 9;
}

.app-mobile .am-navbar-left {
  flex: 0;
}
