.footer {
  overflow: hidden;
  position: relative;
  background: #f2f2f2;
}
.footer .copy {
  width: 100%;
  line-height: 1.75;
  color: #666;
  background: #e6e6e6;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  box-sizing: border-box;
  padding: 10px;
}
.footer .copy i {
  margin-left: 20px;
}
.footer .copy i.police {
  position: relative;
  padding-left: 22px;
}
.footer .copy i.police a {
  color: #666;
}
.footer .copy i.police a:hover {
  color: #333;
}
.footer .copy i.police img {
  position: absolute;
  left: 0;
  top: -2px;
}
