.info {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  margin: 15px 0;
}
.info p {
  font-size: 15px;
  text-indent: 2em;
  text-align: justify;
  line-height: 2;
}
