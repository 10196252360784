.contact {
  margin-top: 20px;
}
.contact .list {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  margin: 10px 0 30px;
}
.contact .list .item {
  display: flex;
  justify-content: center;
  margin: 0 50px;
}
.contact .list .item p {
  margin: 0.5em 0;
}
.contact .list .item em {
  font-weight: bold;
}
.contact .list .item .inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.contact .list .item-img .inner {
  flex: 1;
  align-items: center;
}
.contact .list .item-img img {
  max-width: 80%;
}
.contact .map {
  width: 100%;
  margin: 0 auto;
}
.contact .list .item .icon {
  position: relative;
  width: 18px;
  left: -7px;
  top: 4px;
}
