.title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
}

.title .text {
  color: #333;
  font-size: 20px;
  padding-bottom: 8px;
  display: inline-block;
  position: relative;
  font-weight: bold;
}

.title .text:after {
  content: "";
  width: 30%;
  height: 2px;
  background-color: #c7000b;
  position: absolute;
  left: 50%;
  margin-left: 0;
  transform: translateX(-50%);
  bottom: -2px;
  min-width: 35px;
}
.title.side {
  justify-content: left;
  margin-left: 30px;
}
.title.side .text {
  padding-left: 15px;
}
.title.side .text:after {
  left: 0;
  width: 3px;
  height: 100%;
  min-width: 3px;
  bottom: 5px;
}
