.pic-box {
  width: calc(90% + 30px);
  margin: 0 auto;
}
.pic-list {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
}
.pic-box .item {
  display: flex;
  align-items: center;
  margin: 0 15px;
}
.pic-box .item.big {
  flex: 2;
}
.pic-box .item.small {
  flex: 1;
}
