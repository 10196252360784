.pic {
  position: relative;
  cursor: pointer;
}
.pic .img-box {
  position: relative;
  width: 100%;
  height: 100%;
  background: #999;
  overflow: hidden;
  border-radius: 10px;
}
.pic .img-box img {
  transition: all 0.5s;
  width: 100%;
}
.pic:hover .img-box img {
  transform: scale(1.1);
}

.pic.slide .img-box .mask {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0.4) 70%
  );
  display: block;
  z-index: 2;
}
.pic.slide .text-box {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  text-align: left;
  transition: all 0.5s;
  z-index: 4;
}
.pic.slide .text-box .tit {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
}
.pic.slide .text-box .text {
  font-size: 12px;
  position: absolute;
  bottom: 0;
  padding: 10px;
  text-align: justify;
}
